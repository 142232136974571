import React from 'react'
import { Facebook, Instagram, Linkedin, Youtube } from 'react-feather'
import Tiktok from './../icons/Tiktok'
import Twitter from './../icons/Twitter'

import styles from './SocialLinks.module.scss'
import './SocialLinks.scss'

const platformIconMap = new Map([
    ['facebook', Facebook],
    ['instagram', Instagram],
    ['linkedin', Linkedin],
    ['youtube', Youtube],
    ['tiktok', Tiktok],
    ['twitter', Twitter]
])

/** 
 * Create an HTML class list from an array or nested arrays.
 * 
 * It will exclude any value that is not a string. Nested arrays will be flattened.
*/
const classes = (list = []) => list.flat().filter(item => (item && typeof item === 'string')).join(' ')

export function SocialLinks({
    onClickHandler = () => true,
    size = 1.4,
    showHeader = false,
    headerText = 'Follow Us',
    platforms = [
        {
            platform: 'facebook',
            url: 'https://www.facebook.com/EWScrippsCo',
            displayName: 'Facebook',
        },
        {
            platform: 'twitter',
            url: 'https://twitter.com/EWScrippsCo',
            displayName: 'X',
        },
        {
            platform: 'instagram',
            url: 'https://www.instagram.com/EWScrippsCo',
            displayName: 'Instagram',
        },
        {
            platform: 'linkedin',
            url: 'https://www.linkedin.com/company/the-e-w-scripps-company',
            displayName: 'LinkedIn',
        },
    ]
}) {
    return (
        <div className={classes([styles['social-links'], 'social-links'])}>
            { showHeader && <h4 className={classes([
                styles['social-links__header'], 
                'social-links__header'
            ])}>{ headerText }</h4> }
            { platforms.map((item, index) => {
                const { platform, url, displayName } = item
                const Icon = (item?.image) ? item.image : platformIconMap.get(platform)
                
                return (
                    <a href={url}
                        className={classes([styles['social-link'], 'social-link', `social-link--${platform}`])}
                        onClick={onClickHandler}
                        target="_blank"
                        rel="noreferrer"
                        key={`social-link-${platform}`}
                        title={`Follow us on ${displayName}`}   
                    >                        
                        <i>
                            <span className="sr-only">{ platform }</span>
                            <Icon  
                                className={classes([styles['social-link__icon'], 'social-link__icon'])} 
                                size={`${size}rem`}
                                { ...item.svgAttrs }
                            />
                        </i>
                    </a>
                )
            })}
        </div>
       
    )
}

export default SocialLinks
