import React from 'react'

import './../scss/ViewingPlatforms.scss'

function ViewingPlatforms({platforms, ...props}) {
  return (
    <div className="viewing-platforms constrain-content">
        <h2 className="viewing-platforms__header">Video Platforms</h2>
        <ul className="viewing-platforms__list">
            {platforms.map(platform => {
                return (
                    <li className="viewing-platforms__item" key={platform.platform.name}>
                        <a className="viewing-platforms__link" href={platform.link_url} target="_blank" rel="noreferrer">
                            <img className="viewing-platforms__logo" src={platform.platform.image} alt={platform.platform.name}/>
                        </a>
                    </li>
                )
            })}
        </ul>
    </div>
  )
}

export default ViewingPlatforms