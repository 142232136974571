import React from 'react'
import SocialLinks from './../../components/SocialLinks'

import './../../scss/Footer.scss'

const socialLinks = [
    {
        platform: 'facebook',
        url: 'https://www.facebook.com/IONTelevision',
        displayName: 'Facebook',
    },
    {
        platform: 'twitter',
        url: 'https://twitter.com/iontv/',
        displayName: 'X',
        viewBox: '0 0 30 30',
    },
    {
        platform: 'instagram',
        url: 'https://www.instagram.com/iontelevision/',
        displayName: 'Instagram',
    },
    {
        platform: 'tiktok',
        url: 'https://www.tiktok.com/@iontelevision?lang=en',
        displayName: 'TikTok',
        viewBox: '0 0 30 30',
    },
    {
        platform: 'youtube',
        url: 'https://www.youtube.com/@ion.television',
        displayName: 'YouTube',
    },
]


function Footer() {
  return (
    <footer className='main-footer'>
      <div className='constrain-content'>
        <nav className='footer-nav'>
          <ul className='footer-nav__list'>
            <li className="footer-nav__item"><a className='footer-nav__link' href="https://iontelevision.com">ION Television</a></li>
            <li className="footer-nav__item"><a className='footer-nav__link' href="https://iontelevision.com/privacy-policy">Privacy Policy</a></li>
            <li className="footer-nav__item"><a className='footer-nav__link' href="https://iontelevision.com/privacy-center?domain=ionmystery">Privacy Center</a></li>
            <li className="footer-nav__item"><a className='footer-nav__link' href="https://iontelevision.com/terms-of-use">Terms of Use</a></li>
            <li className="footer-nav__item"><a className='footer-nav__link' href="https://iontelevision.com/faqs">FAQs</a></li>
            <li className="footer-nav__item"><a className='footer-nav__link' href="https://support.ionmystery.com/">Contact</a></li>
            <li className="footer-nav__item social-links-item">
              <span>Follow Us</span><SocialLinks platforms={socialLinks} size={1.1} />
            </li> 
          </ul>
        </nav>
        <div className='copyright'>
          © 2024 ION Media Networks, all rights reserved. All third-party trademarks, including logos, are the property of their respective owners.
        </div>
      </div>
    </footer>
  )
}

export default Footer