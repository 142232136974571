import React, { forwardRef } from 'react';

const Twitter = forwardRef(({ 
    stroke = 'currentColor', 
    strokeWidth = '2',
    strokeLinecap = 'round',
    strokeLinejoin = 'round',
    fill = "#000", 
    size = '24', 
    viewBox = '0 0 30 30',
    style = {
        transform: 'scale(1.5)'
    },
    ...rest 
}, ref) => {
    return (
        <svg
            ref={ref}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox={viewBox}
            fill={fill}
            stroke={stroke}
            strokeWidth={strokeWidth}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
            style={style}
            {...rest}
        >
            <g transform="translate(-5.031 -32)">
                <path d="M6.166,7.852h0l-.514-.775L1.56.909H3.321l3.3,4.977.514.775,4.293,6.47H9.669ZM11.43,0l-4.2,5.147L3.871,0H0L5.075,7.784,0,14H1.147L5.585,8.565,9.129,14H13L7.736,5.927,12.577,0Z" transform="translate(14.031 40)" strokeWidth="0" />
            </g>
      </svg>
    );
  });

  export default Twitter