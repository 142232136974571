import React, { forwardRef } from 'react';

const Tiktok = forwardRef(({ 
    stroke = 'currentColor', 
    strokeWidth = '2',
    strokeLinecap = 'round',
    strokeLinejoin = 'round',
    fill = "none", 
    size = '24', 
    viewBox = '0 0 30 30',
    style = {
        transform: 'scale(1.5)'
    },
    ...rest 
}, ref) => {
    return (
        <svg
            ref={ref}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox={viewBox}
            fill={fill}
            stroke={stroke}
            strokeWidth={strokeWidth}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
            style={style}
            {...rest}
        >
            <g id="off" transform="translate(-5.031 -32)">
                <path id="Path_17" data-name="Path 17" d="M122.882-21.652a3.856,3.856,0,0,0,3.808,3.775v2.553a5.968,5.968,0,0,1-3.766-1.084v4.777c0,6.067-6.748,7.961-9.464,3.61-1.75-2.786-.686-7.7,4.914-7.906v2.69a8.982,8.982,0,0,0-1.3.316,2.168,2.168,0,0,0-1.75,2.553c.378,2.608,5.264,3.39,4.858-1.716v-9.567Z" transform="translate(-99.658 60.651)"/>
            </g>
      </svg>
    );
  });

  export default Tiktok